import mainS from './App.module.css'
import { Route, Routes } from 'react-router-dom'
import { Landing } from './Screens/Landing/Landing'
import { Footer } from './Components/Footer/Footer'

function App() {
  return (
    <div className={mainS.App}>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/*' element={<Landing />} />
      </Routes>
    </div>
  )
}

export default App
