import s from './HeaderSection.module.css'
import mainS from '../../App.module.css'
import { HashLink } from 'react-router-hash-link'
import Logo from '../../res/img/logo.svg'

export const HeaderSection = () => {
  return (
    <div className={s.back}>
      <div className={s.header}>
        <div className={mainS.container}>
          <div className={s.linkList}>
            <img src={Logo} className={s.logo} />
            <HashLink to='#about' smooth className={s.link}>
              About Us
            </HashLink>
            <HashLink to='#product' smooth className={s.link}>
              Product
            </HashLink>
            <HashLink to='#form' smooth className={s.link}>
              Contact Us
            </HashLink>
          </div>
        </div>
      </div>
      <div className={mainS.container}>
        <div className={s.content}>
          <div className={s.title}>BALTIC STEEL CENTER</div>
          <div className={s.subTitle}>Steel plates trading company</div>
          <HashLink to='#form' smooth className={s.btn}>
            Contact Us
          </HashLink>
        </div>
      </div>
    </div>
  )
}
