import { About } from '../../Components/About/About'
import { Features } from '../../Components/Features/Features'
import { HeaderSection } from '../../Components/HeadSection/HeaderSection'
import { Product } from '../../Components/Product/Product'
import { Form } from '../../Components/Form/Form'
import s from './Landing.module.css'
import { Footer } from '../../Components/Footer/Footer'

export const Landing = () => {
  return (
    <div className={s.mainContainer}>
      <HeaderSection />
      <About />
      <Features />
      <Product />
      <Form />
      <Footer/>
    </div>
  )
}
