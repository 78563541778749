import s from './Product.module.css'
import mainS from '../../App.module.css'
import Arrow from '../../res/img/arrow.svg'
import Arrow2 from '../../res/img/arrow2.svg'
import { useState } from 'react'
import { HashLink } from 'react-router-hash-link'

export const Product = () => {
  const [products, setProducts] = useState([
    {
      name: 'Structural steel',
      types: 'S235JR <span>/</span> S275JO <span>/</span> S275J2+N <span>/</span> S355J2+N',
      text: 'Structural steel is a type of steel used to make various forms of building materials. Many forms of structural steel are in the form of slender beams whose profiles have specific cross-sections.',
      open: false,
    },
    {
      name: 'High-quality structural steel',
      types: '10 <span>/</span> 20 <span>/</span> 35 <span>/</span> 45 <span>/</span> 40X <span>/</span> 65G',
      text: 'High-quality structural steel is widely used in mechanical engineering. High quality - within 0.025% sulfur and phosphorus marked with the letter "A" at the end.',
      open: false,
    },
    {
      name: 'Ultra - High - Strength steel',
      types:
        'ALDUR700 <span>/</span> SIMAXX700 <span>/</span> ALFORM900 x-treme <span>/</span> ALDUR900 <span>/</span> SIMAXX900 <span>/</span> SIMAXX1000 <span>/</span> ALFORM550',
      text: 'UHSS is widely used in automotive, engineering, mining, military and aerospace industries due to its high strength, toughness, and ductility. It is reported that the automotive industry is currently using UHSS with a yield strength of up to 1,400 MPa.',
      open: false,
    },
    {
      name: 'Atmospheric Corrosion Resistant steel',
      types: '02G2C <span>/</span> 10GC <span>/</span> 17 GC <span>/</span> 10XCHD',
      text: 'Corten steel  is a type of weathering steel that can be more accurately described as "Atmospheric Corrosion Resistant Steel". It is a copper-chromium alloy steel - an alloy that is more resistant to atmospheric weathering than other mild steels.',
      open: false,
    },
    {
      name: 'Boilers and Pressure Vessel steel',
      types:
        'P265GH - P275NH+ <span>/</span> ASME SA 516 Gr 60 <span>/</span> P355NH-NL2 <span>/</span> ASME SA 516 Gr 70 <span>/</span> 20K <span>/</span> 05G2C <span>/</span> 16GC <span>/</span> 17G1C',
      text: 'Boilers and Pressure Vessel steel Boiler and pressure vessel steels come in a variety of grades and thicknesses to withstand enormous internal pressures. These grades are fully killed fine-grained carbon-manganese steels produced by hot rolling and normalising. Importantly, these types of steel are designed to leak before breaking.',
      open: false,
    },
    {
      name: 'Low-alloyed steel',
      types: 'AMS 5596 <span>/</span> ASTM B 670 <span>/</span> ASME SB 670 <span>/</span> B507F14',
      text: 'A low-alloy steel is a sort of steel combination composed of metal and some other metals that own acceptable residences. Low-alloy metal consists of approximately 1%-5% of alloying elements. Therefore, it possesses unique chemical compositions that offer higher mechanical residences which can be meant to save you corrosion.',
      open: false,
    },
    {
      name: 'Wear Resistant steel',
      types: 'SIDUR 500',
      text: 'Wear-resistant steel is more difficult and tougher than traditional metallic plate and might last as long as four instances longer than traditional high-power metallic.',
      open: false,
    },
    {
      name: 'Alloy steel',
      types: '30XGCA',
      text: 'Alloy steel is a form of metal alloyed with numerous factors together with molybdenum, manganese, nickel, chromium, vanadium, silicon, and boron. These alloying factors are brought to boom strength, hardness, put on resistance, and toughness. The quantities of alloying factors might also additionally range among 1% and 50%.',
      open: false,
    },
  ])

  const openElement = (index, e) => {
    if (!products[index].open) {
      const copy = products.concat()
      copy.forEach((el) => (el.open = false))
      copy[index].open = true
      setProducts(copy)
      //   return e.target.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const closeElement = (index) => {
    const copy = products.concat()
    copy[index].open = false
    setProducts(copy)
  }

  const Products = products.map((el, index) => {
    return (
      <div className={`${s.item} ${el.open ? null : s.close}`} key={`${index}-product`}>
        <div
          className={s.line}
          onClick={(e) => {
            openElement(index, e)
          }}
        >
          <div className={s.name}>
            <h1>{el.name}</h1>
            <p dangerouslySetInnerHTML={{ __html: el.types }} />
          </div>
          <div className={s.more} style={el.open ? { display: 'none' } : {}}>
            <div>More</div>
            <img src={Arrow} />
          </div>
        </div>
        <div className={el.open ? s.content : `${s.content} ${s.contentHidden}`}>
          <img src={require(`../../res/img/products/${index + 1}.png`)} alt={el.name} className={s.image} />
          <div className={s.description}>{el.text}</div>
          <div className={s.lineTwo}>
            <HashLink to='#form' smooth className={s.contact}>
              Contact Us
            </HashLink>
            <div
              className={s.less}
              onClick={() => {
                closeElement(index)
              }}
            >
              <div>Less</div>
              <img src={Arrow2} />
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={mainS.container} id='product'>
      <div className={s.title}>Product</div>
      <div className={s.lidt}>{Products}</div>
    </div>
  )
}
