import s from './Footer.module.css'
import mainS from '../../App.module.css'
import Logo from '../../res/img/logoSmall.svg'

export const Footer = () => {
  return (
    <div className={mainS.container}>
      <div className={s.footer}>
        <img className={s.logo} src={Logo} />
        <div className={s.contacts}>
          <a className={s.contact} href='tel:+37126305160'>
            +37126305160
          </a>
          <div className={s.contact}>Rīga, Ģertrūdes iela 71 - 46, LV-1011</div>
          <a className={s.email} href='mailto:balticsteel@outlook.com'>
            balticsteel@outlook.com
          </a>
        </div>
        <a href='https://www.behance.net/daraeverydcafc' className={s.designer}>
          Designed by
          <br />
          <span>Bohdan Reshetnyk</span>
        </a>
      </div>
    </div>
  )
}
