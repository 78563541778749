import s from './Features.module.css'
import mainS from '../../App.module.css'
import { useState } from 'react'

export const Features = () => {
  const [features, setFeatures] = useState([
    {
      title: 'Great Selection',
      text: 'There is a wide range of different steel grades in stock',
    },
    {
      title: 'Unique Orders',
      text: 'If you have some special requirements for material, we will help you with it',
    },
    {
      title: 'Fast Cooperation',
      text: 'Our specialists will contact as fast as possible after you sent the enquiry form to us',
    },
    {
      title: 'Quality',
      text: 'All material has high quality and will be delivered to you with all certificates',
    },
    {
      title: 'Quick Delivery',
      text: 'All of grades are located in our warehouses and you don’t need to wait for your order',
    },
  ])

  const Features = features.map((el, index) => {
    return (
      <div className={s.item} key={`${index}-feature`}>
        <h1>{el.title}</h1>
        <img src={require(`../../res/img/features/${index + 1}.png`)} alt={el.title} />
        <p>{el.text}</p>
      </div>
    )
  })
  return (
    <div className={mainS.container}>
      <div className={s.title}>Our features</div>
      <div className={s.list}>{Features}</div>
    </div>
  )
}
