import s from './About.module.css'
import mainS from '../../App.module.css'

export const About = () => {
  return (
    <div className={mainS.container} id='about'>
      <div className={s.title}>About our company</div>
      <div className={s.text}>
        Our company supplies a wide range of steel plates, especially we are able to find any steel grade, whatever you
        want. Moreover, there is a large part of different grades in stock. Our propose is to make the process less
        time-consuming and quick for our customers.
      </div>
    </div>
  )
}
