import s from './Form.module.css'
import mainS from '../../App.module.css'
import { useState } from 'react'
import axios from 'axios'

export const Form = () => {
  const [fields, setFields] = useState({
    name: {
      value: '',
      valid: true,
      type: 'text',
      placeholder: 'Your name*',
    },
    email: {
      value: '',
      valid: true,
      type: 'email',
      placeholder: 'Your email*',
    },
    phone: {
      value: '',
      valid: true,
      type: 'tel',
      placeholder: 'Your phone number',
    },
    message: {
      value: '',
      valid: true,
      type: 'text',
      placeholder: 'Message',
    },
  })

  const [succeed, setSucceed] = useState(false)

  const changeHandler = (e) => {
    const copy = { ...fields }
    fields[e.target.name].valid = true
    fields[e.target.name].value = e.target.value
    setFields(copy)
  }

  const Fields = Object.keys(fields).map((key) => {
    if (key !== 'message') {
      return (
        <input
          key={key}
          className={fields[key].valid ? s.input : `${s.input} ${s.invalid}`}
          placeholder={fields[key].placeholder}
          value={fields[key].value}
          onChange={changeHandler}
          name={key}
          autoComplete='off'
        />
      )
    } else {
      return (
        <textarea
          key={key}
          className={fields[key].valid ? s.textarea : `${s.input} ${s.invalid}`}
          placeholder={fields[key].placeholder}
          value={fields[key].value}
          onChange={changeHandler}
          name={key}
          autoComplete='off'
        />
      )
    }
  })

  const validateEmail = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const isFormValid = () => {
    if (!validateEmail(fields.email.value)) {
      return false
    }
    if (!fields.name.value) {
      return false
    }

    return true
  }

  const sendData = async (data) => {
    try {
      const chat_id = '-602318169'
      const token = window._env_.REACT_APP_TELEGRAM_TOKEN
      let dataJSON = ''
      for (const key of Object.keys(data)) {
        dataJSON += `${key}: ${data[key]}%0A`
      }
      // console.log(dataJSON)
      const res = await axios.post(
        `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${dataJSON}`
      )
      if (res.data.ok) {
        setSucceed(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const send = () => {
    if (isFormValid()) {
      console.log('send data')
      let data = {
        name: fields.name.value,
        email: fields.email.value,
      }

      if (fields.phone.value) {
        data.phone = fields.phone.value
      }

      if (fields.message.value) {
        data.details = fields.message.value
      }

      sendData(data)
    } else {
      const copy = { ...fields }
      if (!copy.name.value) {
        copy.name.valid = false
      }
      if (!validateEmail(fields.email.value)) {
        copy.email.valid = false
      }

      setFields(copy)
    }
  }

  return (
    <div className={mainS.container} id='form'>
      <div className={s.title}>Contact us</div>
      {Fields}
      <div className={s.sendDiv}>
        <div className={isFormValid() ? `${s.send} ${s.active}` : s.send} onClick={send}>
          Send
        </div>
        <div className={s.text} style={succeed ? { opacity: 1 } : { opacity: 0 }}>
          Your request has been sent.
          <br />
          We will contact you soon.
        </div>
      </div>
    </div>
  )
}
